import { RouterProvider } from "react-router-dom";
import { router } from "./routers";
import { AuthProvider } from "./contexts/AuthContext";
import { useState, useEffect } from "react";
import { ConfigProvider } from "antd";
import ReactGA from "react-ga4";
import WelcomeTour from "./components/WelcomeTour";
import * as Sentry from "@sentry/react";

if (import.meta.env.MODE === "production") {
  Sentry.init({
    dsn: "https://1df7597f5b1d05d7e95d5db6729082fd@o4507970410971136.ingest.us.sentry.io/4507970518450176",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

function App() {
  const [primary] = useState("#6366f1");
  const [isTourOpen, setIsTourOpen] = useState(false);

  useEffect(() => {
    ReactGA.initialize("G-NHJ184K4B4");
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  useEffect(() => {
    const isFirstTime = localStorage.getItem("tour");
    if (!isFirstTime) {
      setIsTourOpen(true);
    }
    localStorage.setItem("tour", "true");
  }, []);

  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: primary,
          },
        }}
        componentSize={"large"}
      >
        <AuthProvider>
          <>
{/*             <WelcomeTour
              open={isTourOpen}
              onClose={() => setIsTourOpen(false)}
            /> */}
            <RouterProvider router={router} />
          </>
        </AuthProvider>
      </ConfigProvider>
    </>
  );
}

export default App;
