import {
  VideoCameraOutlined,
  CodeOutlined,
  AppstoreOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Card, Col, Row } from "antd";
import { IProps } from "../types";

const interviewTypes = [
  {
    key: "general",
    title: "General Interview",
    description: "Pick this if your interview is general or not specific",
    icon: <VideoCameraOutlined className="text-4xl c" />,
  },
  {
    key: "coding",
    title: "Coding Interview",
    description: "Coding, algorithms, & problem-solving assessment",
    icon: <CodeOutlined className="text-4xl" />,
  },
  {
    key: "system_design",
    title: "System Design",
    description: "Design scalable, efficient, & reliable software systems",
    icon: <AppstoreOutlined className="text-4xl" />,
  },
  {
    key: "product_management",
    title: "Product Management",
    description: "Strategic thinking, prioritization, & product vision",
    icon: <UserOutlined className="text-4xl" />,
  },
];

const Next2 = ({ onNext }: IProps) => {
  return (
    <Card className="h-full">
      <Row gutter={[16, 16]}>
        {interviewTypes.map((item) => (
          <Col key={item.key} xs={24} sm={24} md={12} lg={12} xl={12}>
            <Card
              hoverable
              className="h-full cursor-pointer"
              onClick={() => onNext(item)}
            >
              <div className="flex items-center gap-4">
                {/* text-blue-500  */}
                <div className="text-indigo-500">{item.icon}</div>
                <div>
                  <h3 className="text-lg font-bold">{item.title}</h3>
                  <p className="text-sm text-gray-500">{item.description}</p>
                </div>
              </div>
            </Card>
          </Col>
        ))}
      </Row>
    </Card>
  );
};

export default Next2;
