import React, { useEffect, useState } from "react";
import {
  CloseOutlined,
  DollarOutlined,
  LogoutOutlined,
  MenuOutlined,
  MessageOutlined,
  QuestionCircleOutlined,
  ShareAltOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  ConfigProvider,
  Divider,
  Drawer,
  Dropdown,
  Layout,
  Menu,
  message,
  Space,
  theme,
} from "antd";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Footer } from "antd/es/layout/layout";
import goalsIcon from "./icons/goals.svg";
import documentsIcon from "./icons/documents.svg";
import interviewsIcon from "./icons/interviews.svg";
import { supabase } from "../lib/supabaseClient";
const { Header, Sider, Content } = Layout;

const menuList = [
  {
    key: "/interview",
    icon: <img src={interviewsIcon} alt="" />,
    label: "Interview",
  },
  {
    key: "/jobs",
    icon: <img src={goalsIcon} alt="" />,
    label: "Job Applications",
  },
  {
    key: "/documents",
    icon: <img src={documentsIcon} alt="" />,
    label: "Resume",
  },
  /*   {
    key: "/feedback",
    icon: <MessageOutlined style={{ fontSize: "24px" }} />,
    label: "Feedback",
  }, */
];

// const menuClickHandler = ({ key }: any) => {
//   console.log(key);
// };

// const dropdownList = [
//   {
//     key: "logout",
//     icon: <UserOutlined />,
//     label: <span on>Logout</span>, // "Logout",
//   },
// ];

const AntdLayout = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [breakpoint, setBreakpoint] = useState(false);
  const navigate = useNavigate();
  const [selectedKeys, setSelectedKeys] = useState([]);

  const [open, setOpen] = useState(false);

  const { pathname } = useLocation();

  const {
    token: { colorBgContainer, borderRadiusLG, colorPrimary },
  } = theme.useToken();

  const drawerStyles: DrawerStyles = {
    mask: {
      backdropFilter: "blur(10px)",
    },
    content: {
      boxShadow: "-10px 0 10px #666",
    },
    header: {
      borderBottom: `1px solid ${colorPrimary}`,
    },
    body: {
      // fontSize: token.fontSizeLG,
    },
    footer: {
      // borderTop: `1px solid ${token.colorBorder}`,
    },
  };

  const onClose = () => {
    setOpen(false);
  };

  const menuClickHandler = ({ item, key, keyPath }: any) => {
    console.log(item, key, keyPath);
    setSelectedKeys(keyPath);
    navigate(keyPath.join("/"));
  };
  const signOut = () => {
    supabase.auth
      .signOut()
      .then(() => {
        console.log("User signed out successfully");
        navigate("/login");
      })
      .catch((error) => {
        console.error("Error signing out:", error);
        message.error(error.error_description || error.message);
      });
  };
  //  获取url参数 赋值给selectedKeys
  useEffect(() => {
    console.log(pathname);
    if (!pathname || pathname === "/") {
      // setSelectedKeys(["/dashboard"]);
      navigate("/interview");
      return;
    }
    setSelectedKeys([pathname]);
  }, [pathname]);

  const menuContent = (
    <div className="p-4 h-full mb-8">
      <div className="h-12 text-white mt-4  flex justify-center items-center font-bold text-2xl">
        Ace Interview
      </div>
      <Menu
        onClick={menuClickHandler}
        theme="dark"
        mode="inline"
        defaultSelectedKeys={["interview"]}
        selectedKeys={selectedKeys}
        items={menuList}
        style={{
          backgroundColor: "#222326",
        }}
      />
      {/*       <Divider className="my-4 text-white bg-white" />
      <div className="flex flex-col gap-2 items-center">
        <div className="text-gray-400">
          Available Credits:{" "}
          <span className="inline text-center font-bold text-design-orange">
            0
          </span>{" "}
          <QuestionCircleOutlined />
        </div>
        <button className="inline-flex items-center rounded-md bg-gray-400/10 px-2 py-1 text-xs font-medium text-gray-400 ring-1 ring-inset ring-gray-400/20">
          Your Plan: Free Trial
        </button>
        <Button type="primary" onClick={() => {}} icon={<DollarOutlined />}>
          Subscription
        </Button>
      </div> */}
    </div>
  );

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        style={{
          backgroundColor: "#222326",
        }}
        width={256}
        breakpoint="lg"
        collapsedWidth="0"
        trigger={null}
        onBreakpoint={(broken) => {
          setBreakpoint(broken);
        }}
        onCollapse={(collapsed) => {
          setCollapsed(collapsed);
        }}
      >
        {menuContent}
      </Sider>
      <ConfigProvider
        drawer={{
          styles: drawerStyles,
        }}
      >
        <Drawer
          closeIcon={<CloseOutlined style={{ color: "#fff" }} />}
          title={null}
          placement="left"
          onClose={onClose}
          open={open}
          width={256}
          bodyStyle={{ padding: "0" }}
          rootStyle={{ padding: "0", margin: "0" }} // 外边距设置
          style={{ padding: "0", margin: "0", backgroundColor: "#2E2E2E" }} // 外边距设置
        >
          {menuContent}
        </Drawer>
      </ConfigProvider>
      <Layout>
        <Header
          className="flex justify-between items-center"
          style={{ padding: 0, background: colorBgContainer }}
        >
          {/* {breakpoint ? <MenuOutlined /> : null} */}
          <Button
            type="text"
            icon={breakpoint ? <MenuOutlined /> : null}
            onClick={() => setOpen(!open)}
            style={{
              fontSize: "16px",
              width: 64,
              height: 64,
            }}
          />
          <Space>
            <Button
              type="text"
              className="cursor-pointer right-8"
              onClick={signOut}
              icon={<LogoutOutlined />}
            >
              Logout
            </Button>
            <Avatar
              style={{ backgroundColor: "#6366f1", verticalAlign: "middle" }}
              icon={<UserOutlined />}
              className="cursor-pointer right-8"
              size="large"
            >
              User
            </Avatar>
          </Space>
        </Header>
        <Content
          style={{
            margin: "24px 16px",
            padding: 24,
            minHeight: 280,
            background: "rgb(254 249 246)",
            borderRadius: borderRadiusLG,
          }}
        >
          <Outlet />
        </Content>
        {/* <Footer style={{ textAlign: "center" }}>
          AI ©{new Date().getFullYear()} Created by AceInterview
        </Footer> */}
      </Layout>
    </Layout>
  );
};

export default AntdLayout;
